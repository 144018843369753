import axios from "axios";

export default {
  index() {
    return axios.get("rewards_activities");
  },

  search(query) {
    return axios.get(`/rewards_activities?query=${query}`);
  },

  add(payload) {
    return axios.post("rewards_activities", payload);
  },

  update(id, payload) {
    return axios.put(`rewards_activities/${id}`, payload);
  },

  delete(id) {
    return axios.post(`rewards_activities/${id}/delete`);
  },

  fetchGardenUsers(params = "") {
    return axios.get(`/garden_rewards_centre/rewards${params}`);
  },

  filterGardenUsers(payload, params = "") {
    return axios.post(
      `/garden_rewards_centre/rewards_filter${params}`,
      payload,
    );
  },
  export(payload) {
    return axios.post(`garden_rewards_centre/export`, payload);
  },
};
