<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'form'"
          >Edit discount</el-dropdown-item
        >
        <el-dropdown-item
          v-if="allowDiscountDelete"
          :icon="'eden-icon-delete'"
          :command="'delete'"
          >Delete discount</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :action="'edit'"
      :show.sync="action.visibility"
      :discount="discount"
      @success="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: "DiscountActions",
  props: {
    discount: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DiscountDelete: () => import("./DiscountDelete"),
    DiscountForm: () => import("../../DiscountForm"),
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
    };
  },
  computed: {
    componentVisibility() {
      return this.action.visibility;
    },
    allowDiscountDelete() {
      if (this.allowAccessFor(["admin"])) {
        return true;
      }
      return (
        this.discount.customer_persona_type_ids === null &&
        this.discount.corporate_customer_persona_type_ids === null
      );
    },
  },
  watch: {
    componentVisibility() {
      if (!this.action.visibility) {
        this.action.component = "";
      }
    },
  },
  methods: {
    command(command) {
      this.action.component = `discount-${command}`;
      this.action.command = command;
      setTimeout(() => {
        this.action.visibility = true;
      }, 150);
    },
    emitUpdate() {
      this.$emit(
        "update",
        this.action.command === "form" ? "edit" : this.action.command
      );
    },
  },
};
</script>
