<template>
  <el-drawer
    :title="title"
    :visible.sync="setShow"
    direction="rtl"
    append-to-body
    @close="close"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Name" prop="name" :rules="validateField()">
                <el-input type="text" v-model="form.name" placeholder="" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Activity"
                prop="activity"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.activity"
                  placeholder=""
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Points/Apples Attached"
                prop="points"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model.number="form.points"
                  v-number
                  @blur="validateRewardPoints"
                />
              </el-form-item>
              <span v-if="showRewardMessage" class="reward-message fadeIn"
                >Reward points should be multiple of 10</span
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button plain @click="close">Cancel</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        :disabled="disableButton"
        @click="save"
      >
        {{ action === "edit" ? "Save changes" : "Add activity" }}</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import rewards from "@/requests/discounts/rewards";

export default {
  name: "RewardForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    reward: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        activity: "",
        points: "",
      },
      showRewardMessage: false,
      submitting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action === "add" ? "Add Activity" : "Edit Activity";
    },
    disableButton() {
      return this.form.activity === "" || this.form.points === "";
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.name = this.reward.name;
        this.form.activity = this.reward.activity;
        this.form.points = this.reward.points;
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    validateRewardPoints() {
      if (this.form.points % 10 !== 0) {
        this.form.points = 10;
        this.showRewardMessage = true;

        setTimeout(() => {
          this.showRewardMessage = false;
        }, 4000);
      }
    },
    save() {
      this.action === "edit" ? this.update() : this.add();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        const payload = this.form;

        rewards
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        const payload = this.form;

        rewards
          .update(this.reward.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-message {
  font-size: 14px;
  color: var(--eden-grey-tertiary);
  opacity: 0.7;
  font-style: italic;
  display: block;
  position: relative;
  top: -10px;
}
</style>
