<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <el-button type="primary" @click="showDiscountForm = true"
          >Add Discount</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="220">
            <template slot="header">
              <span>Name</span>
            </template>
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'discounts.discounts.discount',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ scope.row.code }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <span>Customers</span>
            </template>
            <template v-slot="scope">
              <span>{{ scope.row.users_count }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Value</span>
              </div>
            </template>
            <template v-slot="scope">
              <span v-if="scope.row.is_affiliate_code">
                <el-tag :type="'info'">AFFILIATE CODE</el-tag>
              </span>
              <span v-else class="font-sm">
                {{
                  scope.row.discount_type === "percentage"
                    ? `${scope.row.discount_value}%`
                    : `${formatPrice(scope.row.discount_value)}`
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Date Created</span>
            </template>
            <template v-slot="scope">
              <span class="font-sm">
                {{ formatDateAndTime(scope.row.created_at, "do m, y") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Expiry Date</span>
            </template>
            <template v-slot="scope">
              <span class="font-sm">
                {{
                  scope.row.expires_at
                    ? formatDate(scope.row.expires_at, "do m, y")
                    : "Never"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Updated On</span>
            </template>
            <template v-slot="scope">
              <span class="font-sm">
                {{ formatDateAndTime(scope.row.updated_at, "do m, y") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="allowAccessFor(['admin', 'growth'])"
            width="80"
          >
            <template v-slot="scope">
              <discount-actions :discount="scope.row" @update="getDiscounts" />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="to"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No discount codes'" />
    </template>
    <discount-form
      :action="'add'"
      :show.sync="showDiscountForm"
      @success="getDiscounts"
    />
  </div>
</template>

<script>
import DiscountForm from "@/components/DiscountsAndRewards/Discounts/DiscountForm";
import DiscountActions from "@/components/DiscountsAndRewards/Discounts/Discount/Actions/DiscountActions";

import discounts from "@/requests/discounts";
import * as actions from "@/store/action-types";

export default {
  name: "Discounts",
  components: {
    DiscountActions,
    DiscountForm,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: false,
      showDiscountForm: false,
    };
  },
  computed: {
    discounts() {
      return this.$store.getters.discounts;
    },
    from() {
      return this.discounts.pages[this.page].from;
    },
    to() {
      return this.discounts.pages[this.page].to;
    },
    total() {
      return this.discounts.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total : result} Discounts`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.discounts.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getDiscounts();
      }
    },
  },
  created() {
    this.getDiscounts();
  },
  methods: {
    getDiscounts() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_DISCOUNTS, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }
      this.loading = true;
      discounts
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setPageData();
        });
    },
    setPageData() {
      this.pageData = this.discounts.pages[this.page].data;
      this.showPagination = true;
    },
  },
};
</script>
