<template>
  <div class="rewards">
    <eden-alert :type="'info'" class="rewards__alert" v-if="showAlert">
      <div class="is-flex is-justify-between is-align-center w-100">
        <span>
          Activities are actions taken by a customer to earn apples. (1 Apple =
          ₦50)
          <span class="close-btn" @click="showAlert = false">X</span>
        </span>
      </div>
    </eden-alert>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <el-button type="primary" @click="showRewardForm = true"
          >Add Activity</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="pageData.length" :data="pageData">
        <el-table-column>
          <template #header>
            <eden-table-column-header
              :label="'Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm"> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <eden-table-column-header
              :label="'Activity'"
              :property="'activity'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'activity')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm"> {{ scope.row.activity }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <eden-table-column-header
              :label="'Apples Attached'"
              :property="'points'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'points')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ scope.row.points }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'pending_status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'pending_status')"
            />
          </template>
          <template v-slot="scope">
            <el-tag
              :type="setType(scope.row.pending_status ? 'pending' : 'live')"
            >
              {{
                scope.row.pending_status
                  ? "Pending implementation"
                  : "Live on Garden"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['admin', 'growth'])" width="80">
          <template v-slot="scope">
            <reward-actions :reward="scope.row" @update="getRewards" />
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No reward activities'" />
    </template>
    <reward-form
      :show.sync="showRewardForm"
      :action="'add'"
      @success="getRewards"
    />
  </div>
</template>

<script>
import RewardForm from "@/components/DiscountsAndRewards/Rewards/RewardForm";
import RewardActions from "@/components/DiscountsAndRewards/Rewards/Actions/RewardActions";

import rewards from "@/requests/discounts/rewards";

export default {
  name: "Rewards",
  components: {
    RewardForm,
    RewardActions,
  },
  data() {
    return {
      loading: false,
      showAlert: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      pageData: [],
      showRewardForm: false,
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Activities`;
    },
  },
  created() {
    this.getRewards();
  },
  methods: {
    sortPageData(direction, property) {
      this.sort = { property, direction };
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    getRewards() {
      this.loading = true;
      rewards
        .index()
        .then((response) => {
          this.pageData = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.getRewards();
        return;
      }
      this.loading = true;
      rewards
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards {
  &__alert {
    margin-bottom: 30px;
  }
  .close-btn {
    margin-left: auto !important;
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
}
</style>
