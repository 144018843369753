<template>
  <div>
    <eden-page-header :title="'Discounts & Rewards'" />
    <template>
      <div>
        <el-tabs v-model="tab" @tab-click="updateRouteQuery">
          <el-tab-pane label="Discounts" name="discounts">
            <discounts />
          </el-tab-pane>
          <el-tab-pane v-if="enableTab" label="Activities" name="activities">
            <rewards />
          </el-tab-pane>
          <!-- <el-tab-pane v-if="enableTab" label="Badges" name="badges">
            <badges />
          </el-tab-pane> -->
          <!-- <el-tab-pane
            label="Garden Rewards Centre Data"
            name="garden-rewards-centre-data"
          >
            <garden-rewards-centre-data />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
// import GardenRewardsCentreData from "@/components/DiscountsAndRewards/GardenRewards/GardenRewardsCentreData";
import Discounts from "@/components/DiscountsAndRewards/Discounts/Discounts";
import Rewards from "@/components/DiscountsAndRewards/Rewards/Rewards";
// import Badges from "@/components/DiscountsAndRewards/Badges/Badges";

export default {
  name: "DiscountsAndRewards",
  components: {
    // GardenRewardsCentreData,
    Discounts,
    Rewards,
    // Badges,
  },
  data() {
    return {
      tab: "discounts",
    };
  },
  computed: {
    enableTab() {
      return true;
    },
  },
  watch: {
    $route() {
      this.updateRouteQuery();
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "discounts";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "discounts.index",
        query: { t: tab.name },
      });
    },
  },
};
</script>
