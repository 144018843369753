<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="setShow"
      direction="rtl"
      @close="close"
    >
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Discount code"
                  prop="code"
                  :rules="validateField()"
                >
                  <el-input type="text" v-model="form.code" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="allowAffiliateCodeCreate" type="flex">
              <el-col :span="24">
                <el-form-item
                  label=""
                  prop="is_affiliate_code"
                  :rules="validateField()"
                >
                  <el-switch
                    v-model="form.is_affiliate_code"
                    :active-value="true"
                    active-text="This discount is an affiliate code"
                    :inactive-value="false"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!form.is_affiliate_code" type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Discount type"
                  prop="discount_type"
                  :rules="validateField()"
                >
                  <el-radio-group
                    v-model="form.discount_type"
                    class="eden-custom--radio flex"
                  >
                    <el-radio label="percentage">Percentage</el-radio>
                    <el-radio label="fixed-amount">Fixed amount</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="form.discount_type && !form.is_affiliate_code"
              type="flex"
            >
              <el-col :span="24">
                <el-form-item
                  label="Discount value"
                  prop="discount_value"
                  :rules="validateField()"
                >
                  <el-input
                    type="text"
                    v-model.number="form.discount_value"
                    v-number
                    @blur="validateDiscountValue"
                  />
                </el-form-item>
                <span v-if="showDiscountMessage" class="discount-message fadeIn"
                  >Discount cannot be more than
                  {{ form.discount_value > 50 ? "100%" : "49%" }}</span
                >
              </el-col>
            </el-row>
            <el-row v-if="allowAccessFor(['superadmin', 'admin'])" type="flex">
              <el-col :span="24">
                <el-form-item label="">
                  <el-switch
                    v-model="customerType"
                    :active-value="true"
                    active-text="This discount links to a customer type"
                    :inactive-value="false"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="customerType">
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Customer type"
                    prop="customer_type"
                    :rules="validateField()"
                  >
                    <el-radio-group
                      v-model="customer_type"
                      class="eden-custom--radio flex"
                      @change="setCustomerType"
                    >
                      <el-radio label="individual">Individual</el-radio>
                      <el-radio label="corporate">Corporate</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="customer_type !== ''" type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Assign to specific customer type"
                    prop="customer_type"
                    :rules="validateField()"
                  >
                    <el-select
                      v-model="form.customer_type[customer_type]"
                      placeholder="Select or enter customer name"
                    >
                      <el-option
                        v-for="(customer, index) in personas[customer_type]"
                        :key="index"
                        :label="formatName(customer.name)"
                        :value="customer.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <el-row v-if="!customerType" type="flex">
              <el-col :span="24">
                <el-form-item label="">
                  <el-switch
                    v-model="form.one_off"
                    :active-value="true"
                    active-text="This discount can only be used once by a customer"
                    :inactive-value="false"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-switch
                  v-model="expiryDate"
                  :active-value="true"
                  active-text="This discount has an expiry date"
                  :inactive-value="false"
                >
                </el-switch>
              </el-col>
            </el-row>
            <el-row v-if="expiryDate" type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Expiry date"
                  prop="expiry_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.expiry_date"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    placeholder="Select expiry date"
                    :picker-options="{
                      disabledDate: disabledDates,
                    }"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center">
        <el-button plain @click="close">Cancel</el-button>
        <el-button type="primary" :loading="submitting" @click="save">
          {{ action === "edit" ? "Save changes" : "Add discount" }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import discounts from "@/requests/discounts/index";
import individuals from "@/requests/customers/individual";
import corporations from "@/requests/customers/corporate";

export default {
  name: "DiscountForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        code: "",
        discount_type: "",
        discount_value: null,
        customer_type: {
          individual: null,
          corporate: null,
        },
        one_off: false,
        expiry_date: null,
        is_affiliate_code: false,
      },
      customerType: false,
      customer_type: "",
      personas: {
        individual: [],
        corporate: [],
      },
      expiryDate: false,
      showDiscountMessage: false,
      submitting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action === "add" ? "Add Discount" : "Edit Discount";
    },
    allowAffiliateCodeCreate() {
      return this.action === "add" && this.allowAccessFor(["admin", "growth"]);
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getIndividualPersonas();
        this.getCorporatePersonas();

        if (this.action === "edit") {
          const {
            code,
            discount_type,
            discount_value,
            one_off,
            customer_persona_type_ids: individual = null,
            corporate_customer_persona_type_ids: corporate = null,
            expires_at: expiry_date = null,
            is_affiliate_code,
          } = this.discount;

          this.form.code = code;
          this.form.discount_type = discount_type;
          this.form.discount_value = discount_value;
          this.form.one_off = one_off === 1;
          this.form.is_affiliate_code = !!is_affiliate_code;
          if (individual || corporate) {
            this.customerType = true;
            this.customer_type = individual ? "individual" : "corporate";
          }
          this.form.customer_type.individual = individual;
          this.form.customer_type.corporate = corporate;

          if (expiry_date) {
            this.expiryDate = true;
          }
          this.form.expiry_date = expiry_date;
        }
      }
    },
    customerType() {
      if (!this.customerType) {
        this.form.customer_type.individual = null;
        this.form.customer_type.corporate = null;
      }
    },
    expiryDate() {
      if (!this.expiryDate) {
        this.form.expiry_date = null;
      }
    },
  },
  methods: {
    close() {
      this.setDiscountAdd = false;
      this.setShow = false;
      this.$refs.form.resetFields();
      this.customerType = false;
      this.customer_type = "";
      this.expiryDate = false;
      this.form = {
        code: "",
        discount_type: "",
        discount_value: null,
        customer_type: {
          individual: null,
          corporate: null,
        },
        one_off: false,
        expiry_date: null,
        is_affiliate_code: false,
      };
    },
    getIndividualPersonas() {
      individuals
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.personas.individual = response.data.data;
          }
        })
        .catch();
    },
    getCorporatePersonas() {
      corporations
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.personas.corporate = response.data.data;
          }
        })
        .catch();
    },
    validateDiscountValue() {
      if (this.form.discount_type !== "percentage") {
        return;
      }
      const threshold = this.allowAccessFor(["superadmin", "admin"]) ? 100 : 49;
      if (this.form.discount_value > threshold) {
        this.form.discount_value = threshold === 100 ? 100 : 49;
        this.showDiscountMessage = true;

        setTimeout(() => {
          this.showDiscountMessage = false;
        }, 4000);
      }
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      return time < new Date().getTime();
    },
    setCustomerType() {
      if (this.customer_type === "individual") {
        this.form.customer_type.individual = "";
        this.form.customer_type.corporate = null;
      } else if (this.customer_type === "corporate") {
        this.form.customer_type.corporate = "";
        this.form.customer_type.individual = null;
      }
    },
    save() {
      this.action === "edit" ? this.update() : this.add();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        const payload = this.form;

        discounts
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.submitting = true;
        discounts
          .update(this.discount.id, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.submitting = false;
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.submitting = false;
            }
          })
          .catch((error) => {
            this.submitting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

.discount-message {
  font-size: 0.875rem;
  color: var(--eden-grey-tertiary);
  font-style: italic;
  display: block;
  position: relative;
  top: -10px;
}
</style>
